import React from "react"
import styled from "styled-components"
import SEO from "../../components/seo"
import Layout from "../../layouts/layout"
import Banner from "../../components/banner"

const Container = styled.div`
  margin-bottom: 5.7rem;
  img {
    width: 100%;
    height: 100%;
  }
`

const Page = () => {
  return (
    <Layout>
      <SEO title="格泰科技" />
      <Banner img="http://cdn.jerryshi.com/gtkj/20200107150415.jpg" />
      <Container>
        <div style={{ maxWidth: 1200, margin: "20px auto" }}>
          <img src="http://maps.yncce.cn/website/brjybb1.png" alt="1" />
        </div>
        <img src="http://maps.yncce.cn/website/brjy02.png" alt="2" />
        <div style={{ maxWidth: 1200, margin: "20px auto" }}>
          <img src="http://maps.yncce.cn/website/brjybb2.png" alt="1" />
        </div>
        <img src="http://maps.yncce.cn/website/brjy04.png" alt="4" />
      </Container>
    </Layout>
  )
}

export default Page
